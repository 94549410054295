import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { ZiggyVue } from 'ziggy-js';
import {Ziggy as routes} from './ziggy'
import mitt from 'mitt'
import * as Sentry from "@sentry/vue";

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue')
        return pages[`./Pages/${name}.vue`]()
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, typeof Ziggy !== 'undefined' ? Ziggy : routes)

        app.config.globalProperties.emitter = mitt()

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            tracesSampleRate: 0.1,
            // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        return app.mount(el)
    },
    progress: {
        delay: 300,
        color: '#4B5563',
        showSpinner: true,
    },
});
